import { HeaderText, HeaderSubText, LogoContainer, LogoWrapper, TextContainer, HomeText, TextGridContainer, ImageGridContainer } from '../../styled/homePage'

import Bibliography from './bibliography'
import CallToAction from './callToAction'
import UpToDate from './upToDate'

const HomePage = () => {
  return (
    <LogoContainer>
      <TextGridContainer>
        <TextContainer>
          <HeaderText> Een hypotheek regelen met een lach </HeaderText>
          <HeaderSubText> Omdat er zo veel kan </HeaderSubText>

            <HomeText>
              Bij Bisja bent u op de juiste plek voor een geslaagde hypotheek voor uw droomhuis
            </HomeText>

          <Bibliography />

          <CallToAction />

          <UpToDate />
        </TextContainer>
      </TextGridContainer>


      <ImageGridContainer>
        <LogoWrapper>
          <img src="/logo/logo.svg" alt="bisja-logo" width="calc(min(22rem, 80vw) * 1.04)" height="calc(min(22rem, 80vw) * 1.04)" style={{ marginBottom: "calc(min(22rem, 80vw) * -0.04)", marginRight: "calc(min(22rem, 80vw) * -0.04)" }} />
        </LogoWrapper>
      </ImageGridContainer>
    </LogoContainer>
  )
}

export default HomePage