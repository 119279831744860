import { SlideButtonBackground, SlideButtonContainer, SlideButtonIcon, SlideButtonText } from '../../styled/slideButton'

const SlideButton = ({ children }) => {
  return (
    <SlideButtonContainer>
      <SlideButtonBackground />
      <SlideButtonText> { children } </SlideButtonText>
      <SlideButtonIcon className="icon-arrow-right" />
    </SlideButtonContainer>
  )
}

export default SlideButton