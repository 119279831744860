import styled from 'styled-components'

export const SlideButtonContainer = styled.div`
  font-size: 20px;
  font-weight: 200;
  letter-spacing: 1px;

  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1;
  margin: 1px;

  :hover {
    i {
      opacity: 1;
      transform: translateX(-6px);
    }
    p {
      transform: translateX(-6px);
    }
  }
`

export const SlideButtonBackground = styled.div`
  background-color: var(--primary-color);
  width: 100%;
  z-index: 1;
  position: absolute;
  height: 100%;
  top: 5px;
  left: 5px;
`

export const SlideButtonIcon = styled.i`
  opacity: 0;
  font-size: 13px;
  transition: 0.2s;
  position: absolute;
  right: 10px;
  top: 21px;
  transition: transform 1s, opacity .3s;
  z-index: 5;
`

export const SlideButtonText = styled.p`
  width: 100%;
  height: 100%;
  z-index: 5;
  position: relative;
  padding: 13px 30px;
  transition: transform 0.8s;

  ::after {
    content: "";
    border: 1px solid black;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: transform 0.8s;
  }

  :hover::after {
    transform: translateX(6px);
  }
`