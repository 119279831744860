import { useState, useEffect, useRef } from 'react'
import { HeaderStyle, HeaderTextBox, HeaderText, HeaderItems, NavToggleInput, NavToggleLabel, NavStyle, NavLogo, HeaderLink } from '../styled/header'
import { Link } from'react-router-dom'

// used to only update the state once
let oldScroll = 0

const Header = ({ location }) => {
  const [ navStyle, setNavStyle ] = useState({})
  const [ headerTextStyle, setHeaderTextStyle ] = useState({})
  const [ headerStyle, setHeaderStyle ] = useState({})
  const [ domLoaded, setDomLoaded ] = useState(false)

  // used to check if you are viewing the navbar
  const toggleRef = useRef(null)

  useEffect(() => {
    if (
      window.innerWidth <= 800
      && domLoaded
      && toggleRef.current.checked
    ) toggleRef.current.click()

    if (location.hash === "") window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [ location, domLoaded ])

  const toggleShow = e => {
    if (e.target.checked) {
      setNavStyle({
        transform: "scale(1,1)",
        backgroundColor: oldScroll > 50 ? "rgba(255,255,255,.87)" : "white"
      })
      setHeaderTextStyle({
        opacity: "1",
        transition: "opacity 250ms ease-in-out 250ms"
      })
      if (window.scrollY <= 50) setHeaderStyle({ backgroundColor: "white" })
    } else {
      setNavStyle({})
      setTimeout(() => {
        if (window.scrollY <= 50) setHeaderStyle({})
        else setHeaderStyle({ backgroundColor: "rgba(255,255,255,.87)" })
      }, 200)
    }
  }

  const onScroll = () => {
    if (toggleRef.current.checked) return;

    if (window.scrollY > 50 && oldScroll < 50) {
      setHeaderStyle({ backgroundColor: "rgba(255,255,255,.87)" })
    } else if (window.scrollY < 50 && oldScroll > 50) {
      setHeaderStyle({})
    }

    oldScroll = window.scrollY
  }

  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    setDomLoaded(true)

    // returned function will be called on component unmount 
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <HeaderStyle style={headerStyle}>
      <span/>
      <Link to="/">
        <picture>
          <source media='(max-width: 600px)' srcSet='/logo/logo-bisja-blk.svg' />
          <NavLogo src="/logo/logo-bisja-adviesgroep-blk.svg" alt="bisja-adviesgroep-logo" height="56" width="374" />
        </picture>
      </Link>
      <NavStyle style={navStyle}>
        <HeaderItems>
          <HeaderLink to="/" style={{ textDecoration: "none" }}>
            <HeaderTextBox>
              <HeaderText style={headerTextStyle} active={location.pathname === "/"}>
                Home
              </HeaderText>
            </HeaderTextBox>
          </HeaderLink>

          <HeaderLink to="/starter" style={{ textDecoration: "none" }}>
            <HeaderTextBox>
              <HeaderText style={headerTextStyle} active={location.pathname.startsWith("/starter")}>
                Starter
              </HeaderText>
            </HeaderTextBox>
          </HeaderLink>

          <HeaderLink to="/doorstromer" style={{ textDecoration: "none" }}>
            <HeaderTextBox>
              <HeaderText style={headerTextStyle} active={location.pathname.startsWith("/doorstromer")}>
                Doorstromer
              </HeaderText>
            </HeaderTextBox>
          </HeaderLink>

          <HeaderLink to="/senior" style={{ textDecoration: "none" }}>
            <HeaderTextBox>
              <HeaderText style={headerTextStyle} active={location.pathname.startsWith("/senior")}>
                Senior
              </HeaderText>
            </HeaderTextBox>
          </HeaderLink>
        </HeaderItems>
      </NavStyle>
      <NavToggleLabel htmlFor="nav-toggle">
        <NavToggleInput ref={toggleRef} onChange={toggleShow} type="checkbox" id="nav-toggle" aria-label="show navigation bar" />
      </NavToggleLabel>
    </HeaderStyle>
  )
}

export default Header