import styled from 'styled-components'

import HousePattern from '../../assets/house-pattern.png'
import { SHeader } from '../../styled/S-pages'

export const PageBannerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 10rem;
  position: relative;
  margin-bottom: 5rem;

  ::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${HousePattern});
    background-repeat: repeat;
    background-size: 50rem;
    z-index: -1;
  }
`

export const PageBannerContainerBlur = styled(PageBannerContainer)`
  ::before {
    filter: blur(1px);
  }
`

export const PageBannerHeader = styled(SHeader)`
  font-size: 3rem;
  background-color: #f07e21e0;
  padding: .3em .5em;
  border-radius: .5rem;

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }
`

const PageBanner = ({ children }) => {
  if (children) return (
    <PageBannerContainerBlur>
      <PageBannerHeader> { children } </PageBannerHeader> : ""
    </PageBannerContainerBlur>
  )

  return <PageBannerContainer />
}

export default PageBanner