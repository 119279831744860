import { CTAContainer, CTAHeader, CTAInfo, CTAButtonContainer, CTALink } from '../../styled/callToAction'
import SlideButton from '../reusables/slideButton'

const CallToAction = () => {
  return (
    <CTAContainer>
      <CTAHeader> De drie S'en </CTAHeader>
      <CTAInfo> Meer informatie voor de </CTAInfo>
      <CTAButtonContainer>
        <CTALink to="starter">
          <SlideButton> Starter </SlideButton>
        </CTALink>
        <CTALink to="doorstromer">
          <SlideButton> <span style={{textDecoration: "line-through"}} >door</span>Stromer </SlideButton>
        </CTALink>
        <CTALink to="senior">
          <SlideButton> Senior </SlideButton>
        </CTALink>
      </CTAButtonContainer>
    </CTAContainer>
  )
}

export default CallToAction