import styled from 'styled-components'

export const BibliographyBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: .7rem;
  color: black;
  padding: 1.5rem 2rem;
  box-shadow: 10px 10px 6px 0 rgba(0,0,0,.05);
  background: linear-gradient(2deg, var(--secondary-color-55) 65%, var(--secondary-color-88) 67%, var(--secondary-color-33) calc(67% + 1px), transparent);
  margin-bottom: 1rem;

  @media only screen and (max-width: 800px) {
    background: linear-gradient(80deg, var(--background-color) 60%, transparent 95%);
    /* background-color: var(--background-color); */
    padding: 1.5rem;
  }
`

export const BibliographyContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-width: 50em;
  color: black;
  padding: 1rem 3rem;
  border-radius: 2px;

  @media only screen and (max-width: 800px) {
    padding: 1rem .5rem;
    margin: .5rem -.75rem;
  }
`