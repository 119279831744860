import styled from 'styled-components'

export const SHeader = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3rem;

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }
`