import { ImageContainer, TextContainer, TextImageContainer, ImageWrapper, TextWrapper } from '../../styled/textImage'



const TextImage = ({ src, alt, imgStyle, children }) => {
  return (
    <TextImageContainer>
      <TextWrapper>
        <TextContainer>{ children }</TextContainer>
      </TextWrapper>

      <ImageWrapper>
        <ImageContainer src={src} alt={alt} style={imgStyle} />
      </ImageWrapper>
    </TextImageContainer>
  )
}

export default TextImage