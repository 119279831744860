import { BibliographyBanner, BibliographyContainer } from "../../styled/bibliography"

const Bibliography = () => {
  return (
    <BibliographyBanner id="mystory">
      <h2> José Appelman </h2>
      <h3> Even voorstellen </h3>

      <BibliographyContainer>
        <p>
          Eenvoud is onze basis
        </p>
        <p>
          En dat is José Appelman ten voeten uit
        </p>
        <p>
          Met haar 28 jaar ervaring in hypothekenland hoef je haar niets meer te vertellen hoe het werkt.
          De ene hypotheek is zeker de andere niet maar door de jaren heen is bewezen dat door haar creativiteit, 
          kennis, opleiding en know-how bijna 100% van de aanvragen doorgang heeft.
        </p>

      </BibliographyContainer>
    </BibliographyBanner>
  )
}

export default Bibliography