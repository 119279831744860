import { Fragment } from 'react'
import { SHeader } from '../../styled/S-pages'
import { TextImageMainFlex } from '../../styled/textImage'

import PageBanner from '../reusables/pageBanner'
import TextImage from '../reusables/TextImage'

import ElderlyStroll from '../../assets/senior/elderly-stroll.svg'
import ElderlyOnBike from '../../assets/senior/elderly-on-bike.svg'

const SeniorPage = () => {
  return (
    <Fragment>
      <PageBanner />

      <SHeader> Alle hulp voor een prettige (laatste keer) verhuizen </SHeader>

      <TextImageMainFlex>
        <TextImage src={ElderlyStroll} alt={"ouderen met hun kleinzoon en hond lopen door het park"}>
          <p>Een vraag die ik vaak krijg is: <i>"Kunnen wij nog op onze leeftijd een hypotheek krijgen?"</i></p>
          <p>Het antwoord hierop is: <i>"Ja zeker!"</i></p>
          <p>Onze "oudste" klant is 91 jaar.</p><br/>

          <p>
            Sterker nog, een aantal hypotheek verstrekkers heeft Senioren propositie in haar acceptatie beleid.
            Wat dit betekend, leggen we graag aan u uit.
            Één ding is zeker: bijna iedereen staat versteld wat er nog allemaal kan.
          </p>
        </TextImage>


        <TextImage src={ElderlyOnBike} alt={"ouderen op een tandem fiets"}>
          <p>
            Om de overwaarde op voorhand te verzilveren is er een overbruggingshypotheek nodig.
            Dit is dus tijdelijk geld, een voorschot op de winst van je te verkopen woning.
            Er zijn nogal wat veranderingen geweest omtrent hypotheken.
            Daarom is het van belang om vooraf een hypotheek gesprek aan te gaan.
          </p>
        </TextImage>
      </TextImageMainFlex>
    </Fragment>
  )
}

export default SeniorPage