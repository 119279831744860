import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { BibliographyBanner } from './bibliography'

export const CTAContainer = styled(BibliographyBanner)`
  background: linear-gradient(100deg, var(--primary-color, transparent));
  flex-wrap: wrap;
  gap: 1rem 2rem;
`

export const CTAHeader = styled.h5`
  text-align: center;
  font-size: 1.75rem;
  font-family: 'Source Sans Pro', sans-serif;
`

export const CTAInfo = styled.p`
  font-size: 1.1rem;
  margin-top: -1rem;
`

export const CTAButtonContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  justify-content: center;
`

export const CTALink = styled(Link)`
  @media only screen and (max-width: 900px) {
    width: 70%;
  }
`

// export const CTAButton = styled.div`
//   border: 2px solid var(--primary-color);
//   cursor: pointer;
//   padding: .5rem 1rem;
//   border-radius: .2rem;

//   ::before {

//   }

//   :hover {
//     background-color: var(--secondary-color);
//   }
// `