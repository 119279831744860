import { Link } from 'react-router-dom'
import styled from 'styled-components'

import ArrowRight from '../assets/arrow-right.svg'

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-start 2;
  flex-wrap: wrap;
  padding: 3rem 10vw 3rem 10vw;
  color: black;
  background-color: var(--secondary-color);
  gap: 2rem;
  margin-top: var(--footer-spacing);
`

const FooterColumn = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: .2rem 1rem;
  height: min-content;

  @media only screen and (max-width: 500px) {
    width: 100%;
  }
`

const FooterHeader = styled.h4`
  color: var(--primary-color);
  grid-column: 1/3;
  margin-bottom: .5rem;
  text-transform: uppercase;
`

const FooterLink = styled(Link)`
  color: black;
  transition: color .2s;

  :hover {
    color: var(--primary-color);
  }

  @media only screen and (max-width: 600px) {
    grid-column: 1/3;
  }
`

const FooterLinkS = styled(FooterLink)`
  grid-column: 1/3;
  position: relative;
  width: max-content;

  ::after {
    content: "";
    background-image: url(${ArrowRight});
    background-size: contain;
    background-repeat: no-repeat;
    width: 1.1rem;
    height: 1rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -.5rem;
    opacity: 0;
    transition: opacity .3s, right .3s ease-in-out;
  }

  :hover::after {
    opacity: 100%;
    right: -1.25rem;
  }
`

const FooterSubject = styled.p`
  @media only screen and (max-width: 600px) {
    grid-column: 1/3;
    margin-bottom: -.2rem;
    margin-top: .5rem;

    :nth-child(2) {
      margin-top: unset;
    }
  }
`

const FooterA = styled.a`
  color: black;
  transition: color .2s;

  :hover {
    color: var(--primary-color);
  }
`

const FooterFullA = styled(FooterLinkS).attrs({ as: "a" })``

const AdressLink = styled.a`
  display: inherit;
  grid-template-columns: inherit;
  gap: inherit;
  transition: color .2s;
  color: black;

  :hover {
    color: var(--primary-color);
  }
`

const Footer = () => {
  return (
    <FooterContainer>
      <FooterColumn>
        <FooterHeader>Bedrijfsinformatie</FooterHeader>

        <FooterSubject>E-mail </FooterSubject>
        <FooterA href="mailto:info@bisja.nl" target="_blank" rel="noopener noreferrer">info@bisja.nl</FooterA>

        <FooterSubject>Telefoon</FooterSubject>
        <FooterA href="tel:31653200530">06 53200530</FooterA>

        <FooterSubject>KvK</FooterSubject>
        <p>37127560</p>

        <FooterSubject>AFM Nummer</FooterSubject>
        <p>12018056</p>
      </FooterColumn>

      <FooterColumn>
        <FooterHeader>Bezoek Adres</FooterHeader>

        <AdressLink href="https://www.google.com/maps/dir/?api=1&destination=Bisja+Adviesgroep&destination_place_id=ChIJS9A37WypyEcRqnXyakV_hus" target="_blank" rel="noopener noreferrer">
          <p>Adres</p>
          <p>Breed 2</p>

          <p>Postcode</p>
          <p>1621 KB</p>

          <p>Plaats</p>
          <p>Hoorn</p>
        </AdressLink>
      </FooterColumn>

      <FooterColumn>
        <FooterHeader>Pagina's</FooterHeader>

        <FooterLinkS to="/starter">Starter</FooterLinkS>
        <FooterLinkS to="/doorstromer">Doorstromer</FooterLinkS>
        <FooterLinkS to="/senior">Senioren</FooterLinkS>
      </FooterColumn>

      <FooterColumn>
        <FooterHeader>Handige links</FooterHeader>

        <FooterFullA href="https://belastingdienst.nl/" target="_blank" rel="noopener noreferrer">Belastingdienst</FooterFullA>
        <FooterFullA href="https://www.nhg.nl/" target="_blank" rel="noopener noreferrer">Nationale Hypotheek Garantie</FooterFullA>
        <FooterFullA href="https://www.svn.nl/" target="_blank" rel="noopener noreferrer">Stimuleringsfonds Volkshuisvesting</FooterFullA>
        <FooterFullA href="https://www.kifid.nl/over-kifid/" target="_blank" rel="noopener noreferrer">Kifid - financiële klachten</FooterFullA>
      </FooterColumn>

      <FooterColumn>
        <FooterHeader>Juridisch</FooterHeader>

        <FooterFullA href="/doc/Algemene-Voorwaarden-2022.pdf" target="_blank" rel="noopener noreferrer">Algemene Voorwaarden</FooterFullA>
        <FooterFullA href="/doc/Werkgeversverklaring-2022.pdf" target="_blank" rel="noopener noreferrer">Werkgeversverklaring</FooterFullA>
        <FooterFullA href="/doc/Dienst-Verlenings-Document-Hypotheek-2022.pdf" target="_blank" rel="noopener noreferrer">Dienst Verlenings Document (DVD)</FooterFullA>
      </FooterColumn>

      <img src="/logo/logo.svg" alt="bisja logo" width="125" height="125" />
    </FooterContainer>

  )
}

export default Footer