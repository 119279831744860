import { Fragment } from 'react'
import { SHeader } from '../../styled/S-pages'
import { TextImageMainFlex } from '../../styled/textImage'

import PageBanner from '../reusables/pageBanner'
import TextImage from '../reusables/TextImage'

import CoupleAtBench from '../../assets/starter/undraw_pure_love_ay8a.svg'
import FamilyAtPark from '../../assets/doorstromer/family-at-park.svg'

const DoorstromerPage = () => {
  return (
    <Fragment>
      <PageBanner />

      <SHeader> Voor een goed gevoel bij uw volgende huis </SHeader>

      <TextImageMainFlex>
        <TextImage src={CoupleAtBench} alt={"vrienden blij met hun nieuwe huis"}>
          <p>
            Je bent ooit gestart, hebt leuke jaren in deze woning mogen beleven maar nu is het tijd om verder te groeien.
            Misschien komt er gezinsuitbreiding, wil je gewoon een extra kamer of een tuin, noem maar op.
            Met, hopelijk, enige overwaarde begeef je je tussen het huizen aanbod.
          </p>
        </TextImage>

        <TextImage src={FamilyAtPark} alt={"jonge familie hebben plezier in het park"}>
          <p>
            Om de overwaarde op voorhand te verzilveren is er een overbruggingshypotheek nodig.
            Dit is dus tijdelijk geld, een voorschot op de winst van je te verkopen woning.
            Er zijn nogal wat veranderingen geweest omtrent hypotheken.
            Daarom is het van belang om vooraf een hypotheek gesprek aan te gaan.
          </p>
        </TextImage>
      </TextImageMainFlex>
    </Fragment>
  )
}

export default DoorstromerPage