import { CTAContainer, CTAHeader } from '../../styled/callToAction'
import styled from 'styled-components'

const SmallHeader = styled.h4`
  margin-bottom: -.5rem;
  width: 100%;
`

const UpToDate = () => {
  return (
    <CTAContainer>
      <CTAHeader>Actuele informatie</CTAHeader>

      <SmallHeader>Hypotheek oversluiten</SmallHeader>
      <p>
        70% van de bestaande hypotheek klanten is zijn huidige hypotheek aan het herzien.<br/><br/>

        Oversluiten naar een lagere rente of andere hypotheekvorm?
        Doe dat nu:
        Nu is de rente nog laag.  Hij is wat stijgende, maar nog steeds byzonder laag.
        Dit geldt ook voor een <b>verbouwing</b>.<br/><br/>
        Bel ons om ook uw hypotheek eens tegen het licht te houden. Er kan wellicht meer dan u denkt!
        <br/><br/>
      </p>

      <SmallHeader>Uw woning verduurzamen?</SmallHeader>
      <p>
        Bij een aantal geldverstrekkers is het mogelijk om extra rentekorting te krijgen als u uw woning gaat verduurzamen. Vraag naar de mogelijkheden.
      </p>

    </CTAContainer>
  )
}

export default UpToDate