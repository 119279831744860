import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const HeaderStyle = styled.div`
  text-align: center;
  z-index: 999;
  width: 100%;
  position: sticky;
  top: 0;
  transition: background-color .3s;

  @media only screen and (min-width: 800px) {
    display: grid;
    grid-template-columns: 10vw 374px auto 10vw;
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 10vw auto 10vw;

    a {
      grid-column: 1 / 4;
    }
  }
`

export const NavStyle = styled.div`
  position: absolute;
  text-align: left;
  top: 100%;
  left: 0;
  background: rgba(255,255,255,.87);
  width: 100%;
  transform: scale(1, 0);
  transform-origin: top;
  transition: transform 400ms ease-in-out;

  @media only screen and (min-width: 800px) {
    padding: .5rem 0;
    /* the following lines are not from my video, but add Edge support */
    position: relative;
    text-align: left;
    transition: none;
    transform: scale(1,1);
    background: none;
    top: initial;
    left: initial;
    /* end Edge support stuff */
    
    grid-column: 3 / 4;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  @media only screen and (max-width: 1200px) {
    grid-column: 1 / 4;
    justify-content: center;
  }
`

export const HeaderItems = styled.div`
  @media only screen and (min-width: 800px) {
    display: flex;
    gap: min(2em, 4vw);
  }
`

export const HeaderLink = styled( Link )`
  text-decoration: none;
`

export const HeaderTextBox = styled.div`
  margin-bottom: 1em;
  margin-left: 1em;

  @media only screen and (min-width: 800px) {
    margin-left: unset;
    margin-bottom: unset;
  }
`

export const HeaderText = styled.p`
  --active-underline: calc(100% + .3em);

  color: black;
  width: max-content;
  text-transform: uppercase;
  opacity: 0;
  transition: opacity 150ms ease-in-out;
  position: relative;
  font-size: 1.2rem;
  font-weight: 300;
  font-family: 'Arimo', sans-serif;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 1px;
    width: ${p => p.active ? "var(--active-underline)" : "0%"};
    background-color: var(--dark-text);
    display: block;
    content:'';
    transition: width .4s cubic-bezier(0.8, 0, 0.4, 1);
  }

  &:hover::after{
    width: var(--active-underline);
  }

  @media only screen and (min-width: 800px) {
    opacity: 1;
    position: relative;
  }
`

export const NavLogo = styled.img`
  margin: .5rem 0;
  height: 3.5rem;
`

export const NavToggleInput = styled.input`
  display: block;
  background-color: black;
  height: .2em;
  width: 2em;
  border-radius: 1em;
  position: relative;
  appearance: none;
  outline: unset;
  transition: transform .4s ease-in-out, background-color .5s;
  cursor: pointer;

  &::after,
  &::before {
    display: block;
    background-color: black;
    height: .2em;
    width: 2em;
    border-radius: 1em;
    position: relative;
    content: '';
    position: absolute;
    transition: transform .4s ease-in-out;
  }

  &::after {
    transform: translateY(-.6em)
  }

  &::before {
    transform: translateY(.6em)
  }

  &:checked {
    transform: translateX(-2em);
    background-color: transparent;
  }

  &:checked::after {
    transform: rotateZ(45deg) translate(1.4em, -1.4em);
  }

  &:checked::before {
    transform: rotateZ(-45deg) translate(1.4em, 1.4em);
  }
`

export const NavToggleLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  margin-left: 1em;
  height: 100%;
  display: flex;
  align-items: center;

  @media only screen and (min-width: 800px) {
    display: none;
  }
`