import styled from 'styled-components'

export const LogoContainer = styled.div`
  --right-px: 550px;
  --header-compensation: -4.7rem;

  margin-top: var(--header-compensation);
  min-height: 100vh;
  background: linear-gradient(-100deg, var(--secondary-color) calc(var(--right-px) + 10vw), transparent calc(var(--right-px) + 1px + 10vw));

  overflow-x: hidden;

  display: grid;
  grid-template-columns: 1fr var(--right-px);
  grid-auto-flow: column;
  margin-bottom: calc(-1 * var(--footer-spacing));

  @media only screen and (max-width: 1500px) {
    --right-px: 390px;
  }
  @media only screen and (max-width: 1200px) {
    --right-px: 260px;
    --header-compensation: -7.1rem;
  }
  @media only screen and (max-width: 800px) {
    --right-px: 40%;
    --header-compensation: -4.75rem;
    grid-template-columns: 1fr;
    grid-auto-flow: row;
    padding-top: calc(calc(-1 * var(--header-compensation)) + 2rem);
  }
`

export const TextGridContainer = styled.div`
  width: 100%;
  position: relative;
  padding-top: calc(4.5rem + 10vh);
  padding-left: 5vw;

  @media only screen and (max-width: 800px) {
    grid-row: 2;
    padding-left: 2.5vw;
  }
`

export const ImageGridContainer = styled(TextGridContainer)`
  @media only screen and (max-width: 800px) {
    grid-row: 1;
  }
`

export const TextContainer = styled.div`
  width: calc(75vw - var(--right-px));

  @media only screen and (max-width: 800px) {
    padding-top: 2.5vh;
    width: 95vw;
    left: 2.5vw;
  }
`

export const HeaderText = styled.h1`
  font-size: 2.2rem;
`

export const HeaderSubText = styled.h2`
  margin-left: 1.5rem;
  margin-top: .3em;
  font-size: 1.8rem;
  font-style: italic;
`

export const HomeText = styled.p`
  margin: 2rem 1.5rem;
  font-size: 1.2rem;
`

export const LogoWrapper = styled.div`
  position: absolute;
  width: min(22rem, 80vw);
  height: min(22rem, 80vw);
  border-radius: 50%;
  top: 50%;
  right: var(--right-px);
  transform: translate(35%, -50%);
  filter: drop-shadow(0px 0px 5px rgba(0,0,0,.5));

  @media only screen and (max-width: 800px) {
    right: 50%;
    transform: translate(50%, -30%);
  }

  img {
    /* correction for the roof that extends outside the circle */
    width: calc(min(22rem, 80vw) * 1.04);
    height: calc(min(22rem, 80vw) * 1.04);
    margin-right: calc(min(22rem, 80vw) * -0.04);
    margin-bottom: calc(min(22rem, 80vw) * -0.04);
  }
`

export const LogoImg = styled.img`
  width: min(80vw, 800px);
  height: min(8vw, 80px);
  margin-top: max(-4%,-40px);
  margin-bottom: max(-5%,-48px);
  position: inherit;
  z-index: 200;
  margin-bottom: min(3rem, 4vw);
`