import styled from 'styled-components'

export const TextImageMainFlex = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: column;
  align-items: center;
`

export const TextImageContainer = styled.div`
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
`

export const TextWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  width: min(90vw, 550px);
`

export const TextContainer = styled.div`
  margin-bottom: 2rem;
  font-size: 1.2rem;
`

export const ImageWrapper = styled.div`
  width: min(350px, 90vw);
  position: relative;
  filter: drop-shadow(6px 3px 3px rgba(0,0,0,.25));
`

export const ImageContainer = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`