import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom'

import Header from './header'
import Footer from './footer'

import HomePage from './home'
import Starter from './starter'
import DoorstromerPage from './doorstromer'
import SeniorPage from './senior'

const PageRouter = () => {
  const history = useHistory()

  return (
    <BrowserRouter history={history}>
      <Route path="/" component={Header} />

      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/starter" component={Starter} />
        <Route path="/doorstromer" component={DoorstromerPage} />
        <Route path="/senior" component={SeniorPage} />
      </Switch>

      <Route path="/" component={Footer} />
    </BrowserRouter>
  )
}

export default PageRouter