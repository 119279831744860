import { Fragment } from 'react'
import { SHeader } from '../../styled/S-pages'
import { TextImageMainFlex } from '../../styled/textImage'

import PageBanner from '../reusables/pageBanner'
import TextImage from '../reusables/TextImage'

import FriendsHousewarming from '../../assets/starter/friends-housewarming.svg'
import CouplePaintingNewHouse from '../../assets/starter/couple-painting-new-house.svg'

const StarterPage = () => {
  return (
    <Fragment>
      <PageBanner />

      <SHeader> De juiste hulp voor een goede start </SHeader>

      <TextImageMainFlex>
        <TextImage src={FriendsHousewarming} alt={"vrienden vieren dat ze een nieuw huis hebben gekocht"} imgStyle={{ transform: "scale(1.35) translateY(17.5%)" }}>
          <p>
            <b>Eindelijk!</b><br/>
            Je hebt een baan, vast of tijdelijk, en wilt op eigen benen staan:
            Zelf weten wat je gaat eten, de TV is voor je favo programma’s en je bepaald zelf waar je je schoenen uitgooit!
            Een eigen woning is dan helemaal fantastisch. Jouw wooncarrière.
          </p>
        </TextImage>


        <TextImage src={CouplePaintingNewHouse} alt={"jong stel verft hun nieuw gekochte huis"}>
          <p>
            Als starter kun je in aanmerking komen voor een starterslening. Afhankelijk in welke gemeente je gaat wonen.
            Koop je een bestaande woning, dan hoef je als starter eventueel geen overdrachtsbelasting te betalen. Scheelt weer op je spaarrekening.
            Koop je nieuwbouw, dan is er vaak een hogere hypotheek mogelijk in verband met het energie label. Bedragen zijn vaak € 9.000,-/15.000,- bovenop je maximale hypotheeksom.
          </p>
        </TextImage>
      </TextImageMainFlex>
    </Fragment>
  )
}

export default StarterPage